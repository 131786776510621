<template>
  <v-alert
    border="left"
    type="warning"
    close-label="閉じる"
    dismissible
  >
    集計済みの日計があります。データ追加や変更を行う場合は再集計を行う必要があるかご確認の上、続行してください。
    <v-checkbox
      v-model="neverAfterJobEditWarning"
      label="今後このメッセージを表示しない"
      hide-details
      dense
    />
  </v-alert>
</template>

<script>
import { computed } from '@vue/composition-api'
import useUserPreferences from '@/views/v2-temp/common/composables/useUserPreferences'

export default {
  setup() {
    const { preferences } = useUserPreferences()

    const neverAfterJobEditWarning = computed({
      get: () => preferences.value.eod_closing.neverAfterJobEditWarning,
      set: val => {
        preferences.value.eod_closing.neverAfterJobEditWarning = val
      },
    })

    return {
      neverAfterJobEditWarning,
    }
  },
}
</script>
